import * as React from "react";

export const LabelSpec = (props: any) => {
  const { req } = props;

  return (
    <div>
      {props.children} &nbsp;
      {req && <span style={{ color: "red" }}>*</span>}
    </div>
  );
};
