import * as React from "react";

export const genders = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Other", value: "Other" },
];

export const relatedOptions = [
  { label: "Related", value: "related" },
  { label: "Not Related", value: "not related" },
];

export const assessmentDDOptions = ["Related", "Not Related"];
export const assessmentPIOptions = ["Related", "Not Related", "Possibly Related"];
export const statusOptions = ["New", "In Progress", "Complete", "Closed"];
export const piccAssessOptions = [
  "Pending",
  "Accepted",
  "Denied",
  "Inconclusive",
  "Non PICC Claim",
];
