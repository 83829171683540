import * as React from "react";

import "./../style/DosnpPicc.css";

import DeletePng from "./../img/delete.png";
import DocPng from "./../img/doc.png";
import PdfPng from "./../img/pdf.png";
import ExcelPng from "./../img/xls.png";
import Download from "./../img/download.png";
import MsgPng from "./../img/msg.png";
export const RenderAttachments = (props: any) => {
  return (
    <div>
      {(() => {
        if (props.data.fileName.toLowerCase().endsWith(".pdf")) {
          return (
            <div className="divAttachments">
              <div>
                <img src={PdfPng} style={{ width: "24px" }} />
              </div>
              <div>
                {" "}
                <a
                  href={props.data.fileUrl}
                  target="_blank"
                  download={props.data.fileName}
                >
                  {props.data.fileName}
                </a>
              </div>
              <div>
                {" "}
                <img
                  src={DeletePng}
                  onClick={props.removefile.bind(this, props.data)}
                  style={{ width: "20px", cursor: "pointer" }}
                />
              </div>
              <div>
                {" "}
                <img
                  src={Download}
                  onClick={props.download.bind(this, props.data)}
                  style={{ width: "20px", cursor: "pointer" }}
                />
              </div>
            </div>
          );
        } else if (props.data.fileName.toLowerCase().endsWith(".xlsx")) {
          return (
            <div className="divAttachments">
              <div>
                <img src={ExcelPng} style={{ width: "24px" }} />
              </div>
              <div>
                {" "}
                <a
                  href={props.data.fileUrl}
                  target="_blank"
                  download={props.data.fileName}
                >
                  {props.data.fileName}
                </a>
              </div>
              <div>
                {" "}
                <img
                  src={DeletePng}
                  onClick={props.removefile.bind(this, props.data)}
                  style={{ width: "20px", cursor: "pointer" }}
                />
              </div>
              <div>
                {" "}
                <img
                  src={Download}
                  onClick={props.download.bind(this, props.data)}
                  style={{ width: "20px", cursor: "pointer" }}
                />
              </div>
            </div>
          );
        } else if (
          props.data.fileName.toLowerCase().endsWith(".docx") ||
          props.data.fileName.endsWith(".doc")
        ) {
          return (
            <div className="divAttachments">
              <div>
                <img src={DocPng} style={{ width: "24px" }} />
              </div>
              <div>
                {" "}
                <a
                  href={props.data.fileUrl}
                  target="_blank"
                  download={props.data.fileName}
                >
                  {props.data.fileName}
                </a>
              </div>
              <div>
                {" "}
                <img
                  src={DeletePng}
                  onClick={props.removefile.bind(this, props.data)}
                  style={{ width: "20px", cursor: "pointer" }}
                />
              </div>
              <div>
                {" "}
                <img
                  src={Download}
                  onClick={props.download.bind(this, props.data)}
                  style={{ width: "20px", cursor: "pointer" }}
                />
              </div>
            </div>
          );
        }
        else if (
          props.data.fileName.toLowerCase().endsWith(".msg") ||
          props.data.fileName.endsWith(".msg")
        ) {
          return (
            <div className="divAttachments">
              <div>
                <img src={MsgPng} style={{ width: "24px" }} />
              </div>
              <div>
                {" "}
                <a
                  href={props.data.fileUrl}
                  target="_blank"
                  download={props.data.fileName}
                >
                  {props.data.fileName}
                </a>
              </div>
              <div>
                {" "}
                <img
                  src={DeletePng}
                  onClick={props.removefile.bind(this, props.data)}
                  style={{ width: "20px", cursor: "pointer" }}
                />
              </div>
              <div>
                {" "}
                <img
                  src={Download}
                  onClick={props.download.bind(this, props.data)}
                  style={{ width: "20px", cursor: "pointer" }}
                />
              </div>
            </div>
          );
        }
      })()}
    </div>
  );
};

//https://pauledenburg.com/download-file-from-backend-api-with-react/
//https://helicaltech.com/save-image-mysql-display-using-nodejs-sequelizejs/
//endsWith(".docx")
/**
 *   if (props.data.fileExtension == "image/png") {
              return (
                <div className="divAttachments">
                  <img src={props.data.fileUrl} height="40" width="40" alt={props.data.fileName }></img>
                  <img  src="/src/webparts/dosnpPicc/components/img/delete.png"  onClick={removeThis.bind(this, props.data)} style={{width:"24px"}} />
                </div>
              )
            } else

              <div> <img src={DeletePng} onClick={props.download.bind(this, props.data)}  style={{width:"20px"}} /></div>
               <div>FS: <img src={Download} onClick={props.download1.bind(this, props.data)}  style={{width:"20px",cursor: "pointer"}} /></div>
                 <div> <img src={Loader}   style={{display:props.displayLoader,width:"20px"}} /></div>
 */
