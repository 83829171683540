import * as React from "react";
import { useState, useEffect } from "react";
import * as ReactDOM from "react-dom";
import axios from "axios";
import { Dialog } from "@progress/kendo-react-dialogs";
import {
  Form,
  Field,
  FormElement,
  FieldWrapper,
} from "@progress/kendo-react-form";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Error } from "@progress/kendo-react-labels";
import { Checkbox, Input, TextArea } from "@progress/kendo-react-inputs";
import Loader from "./../img/loading.gif";

import { Button } from "@progress/kendo-react-buttons";
import {
  Upload,
  UploadFileInfo,
  UploadOnAddEvent,
  UploadOnRemoveEvent,
  UploadOnProgressEvent,
  UploadOnStatusChangeEvent,
} from "@progress/kendo-react-upload";
import {
  FormDatePicker,
  FormNumericTextBox,
  FormInput,
  FormCheckbox,
  FormMaskedTextBox,
  FormTextArea,
  FormDropDownList,
  FormAutoComplete,
  FormRadioGroup,
  FormComboBox,
} from "./form-components";
import { LabelSpec } from "./LabelSpec";
import { RenderAttachments } from "./RenderAttachments";

import getProtocols, {
  getSitesByStudy,
  getProtocolsByNumber,
  getClaimAttachmentDetails,
  getDownloadAttachment,
} from "./../services/getServicesPromise.js";
import {
  removeAttachment,
  getBucketgetObjecturl,
} from "./../services/postServicePromise";

import {
  genders,
  statusOptions,
  assessmentDDOptions,
  assessmentPIOptions,
  piccAssessOptions,
} from "./fromOptions";

const PICCForm = (props: any) => {
  const [filteredProtocolData, setFilteredProtocolData] = useState([]);
  const [protocolsOpen, setProtocolsOpen] = useState(false);
  const [protocolSearch, setProtocolSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [filteredIndicationData, setFilteredIndicationData] = useState([]);
  const [filteredCountry, setfilteredCountry] = useState([]);
  const [protocolData, setProtocolData] = useState(props.allStudies);

  const [allStudies, setAllStudies] = useState([]);

  const [indicationData, setIndicationData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [filteredProductData, setFilteredProductData] = useState([]);
  ///////////////
  const [siteData, setSiteData] = useState([]);
  const [site, setSite] = useState({});
  const [piList, setPIList] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [selectedPI, setPI] = useState({});
  const [selectedCountry, setCountry] = useState("");
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  const [FileLoader, setFileLoader] = useState("none");
  const fromTitle =
    props.mode === "edit"
      ? "Edit : " + props.item.incidentNum
      : "New PICC Claim";

  const claimID = props.item.Id;

  useEffect(() => {
    if (protocolSearch.length > 2) {
      console.log(protocolSearch.length, protocolSearch);

      const timer = setTimeout(async () => {
        setLoading(true);
        //let filteredProtocols = filterData(protocolSearch)
        let studies = await getProtocolsByNumber(protocolSearch);
        setFilteredProtocolData(studies);
        setLoading(false);
        setProtocolsOpen(true);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [protocolSearch]);

  useEffect(() => {
    fetchAllStudies();
  }, []);

  useEffect(() => {
    fetchAttachments();
  }, []);

  const fetchAttachments = async () => {
    let claimAttachments = await getClaimAttachmentDetails(claimID);

    console.log("claimAttachments : ", claimAttachments);
    setAttachmentFiles(claimAttachments);
  };

  const fetchAllStudies = async () => {
    let studies: any = [];
    studies = await getProtocols();
    console.log(studies);
    if (studies) {
      setProtocolData(studies);
      //setFilteredProtocolData(allStudies);

      let distinctIndications: any = [];
      let distinctProducts: any = []; // array of object like {Product: "product name", Indication: "indication for this product"}
      console.log(Array.isArray(studies));
      if(Array.isArray(studies)){
      studies.map((protocol: any) => {
        let indFound = distinctIndications.filter(
          (ind: any) => ind == protocol.ProtocolIndication
        );
        let productFound = distinctProducts.filter(
          (prod: any) => prod.Product == protocol.Product
        );
        if (indFound.length == 0) {
          distinctIndications.push(protocol.ProtocolIndication);
        }
        if (productFound.length == 0 && protocol.Product) {
          distinctProducts.push({
            Product: protocol.Product,
            Indication: protocol.ProtocolIndication,
            ProductCode: protocol.ProductCode,
          });
        }
      });
    }
      distinctProducts = distinctProducts.sort(sortByProduct);
      distinctIndications = distinctIndications.sort();

      setIndicationData(distinctIndications);
      setFilteredIndicationData(distinctIndications);
      setProductData(distinctProducts);
      setFilteredProductData(distinctProducts);
    }
    setAllStudies(studies);
    if (props.mode === "edit") {
      //onProtocolChange(null,null);
      //setProtocolSearch(props.item.protocolNum);
      /////Get Site Data:
      createSiteData(props.item.protocolNum, null);
    }
  };

  const createSiteData = async (protocolNumber: any, frp: any) => {
    //try {

    console.log("SITES", allStudies);
    let sites: any = [];
    sites = await getSitesByStudy(protocolNumber);
    let distinctPIs: any = [];
    let distinctCountries: any = [];

    sites.map((site: any) => {
      site.PIFullName = `${site.PIFirstName} ${site.PILastName}`;

      let foundPI = distinctPIs.filter((pi: any) => {
        return (
          pi.PIFirstName == site.PIFirstName && pi.PILastName == site.PILastName
        );
      });

      let foundCountry = distinctCountries.filter((country: any) => {
        return country == site.Country;
      });
      if (foundPI.length === 0)
        distinctPIs.push({
          PIFullName: `${site.PIFirstName} ${site.PILastName}`,
          PIFirstName: site.PIFirstName,
          PILastName: site.PILastName,
          PINumber: site.PINumber,
        });
      if (foundCountry.length === 0) distinctCountries.push(site.Country);
    });
    console.log(distinctPIs, distinctCountries);
    setPIList(distinctPIs);
    setCountryData(distinctCountries);
    setfilteredCountry(distinctCountries);
    setSiteData(sites);
    if (frp !== null) {
      if (distinctCountries.length == 1) {
        frp.onChange("siteCountry", { value: distinctCountries });
      }
    }
    //setCountry(distinctCountries);
    // } catch (err) {
    //     alert("Error fetching Sites:", JSON.stringify(err, null, 2))
    // }
  };

  const onProtocolChange = async (event: any, frp: any) => {
    console.log("ON PROTOCOL CHANGE");
    let studySearch = event.target.value; //.ProtocolNumber; // P# for ComboBox
    let studyVal =
      studySearch && studySearch.hasOwnProperty("ProtocolChange")
        ? studySearch.ProtocolNumber
        : studySearch;
    if (studyVal) {
      let filteredCompData = event.target.props.data;
      const eventType = event.nativeEvent.type;
      const valueSelected =
        eventType === "click" ||
        (eventType === "keydown" && event.nativeEvent.keyCode === 13);
      if (valueSelected) {
        let getMatch = filteredCompData.find(
          (f: any) => f.ProtocolNumber == studyVal
        );
        if (getMatch) {
          console.log("CLOSING TIME");
          setProtocolsOpen(false);
          //setIndication(getMatch.ProtocolIndication)
          //setProduct({ Indication: getMatch.ProtocolIndication, Product: getMatch.Product })
          frp.onChange("indication", { value: getMatch.ProtocolIndication });
          frp.onChange("product", {
            value: {
              Indication: getMatch.ProtocolIndication,
              Product: getMatch.Product,
              ProductCode: getMatch.ProductCode,
            },
          });
          //frp.onChange("",{value:{}})
          /////Get Site Data:
          createSiteData(studyVal, frp);
        }
      } else if (studySearch.length > 2) {
        setProtocolSearch(studySearch);
      }
    } else {
      frp.onChange("indication", { value: null });
      frp.onChange("product", { value: null });
      frp.onChange("siteCountry", { value: null });
      frp.onChange("site", { value: null });
      frp.onChange("piFullName", { value: null });
    }
  };
  const sortByProduct = (a: any, b: any) => {
    const pA = a.Product.toUpperCase();
    const pB = b.Product.toUpperCase();

    let comparison = 0;
    if (pA > pB) {
      comparison = 1;
    } else if (pA < pB) {
      comparison = -1;
    }
    return comparison;
  };

  const onSiteChange = (e: any, frp: any) => {
    let val = e.target.value;
    console.log("Site", val);
    if (val) {
      setSite(val);

      if (val.hasOwnProperty("PIFullName")) {
        let pi = {
          PIFullName: val.PIFullName,
          PIFirstName: val.PIFirstName,
          PILastName: val.PILastName,
          PINumber: val.PINumber,
        };
        frp.onChange("piFullName", { value: pi });
        //setPI(pi)
      }
      if (val.hasOwnProperty("Country")) {
        frp.onChange("siteCountry", { value: val.Country });
        // setCountry(val.Country);
      }
    } else {
      frp.onChange("siteCountry", { value: null });
      frp.onChange("piFullName", { value: null });
    }
  };

  const onDeleteFile = async (fileProps: any) => {
    console.log("fileProps :", fileProps);
    if (
      window.confirm("Do you really want to remove" + fileProps.fileName + "?")
    ) {
      await removeAttachment(fileProps);
      fetchAttachments();
    }
  };

  /*const onDownloadFile1=async(fileProps:any)=>{
        await getDownloadAttachment(fileProps.Id).then(attachmet=>{
            var a = document.createElement("a");
            console.log("claimAttachments : ",attachmet); 
            var blob = b64toBlob(attachmet[0].fileBuffer, fileProps.fileExtension);
            //var file = new Blob([attachmet[0].fileBuffer1], {type: 'application/pdf'});
            var fileURL = window.URL.createObjectURL(blob);
            a.href = fileURL;
            a.download = fileProps.fileName;
            a.click();
          
        });
      }*/
  const b64toBlob = (b64Data: any, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };
  const onDownloadFile = async (fileProps: any) => {
    setFileLoader("");
    var a = document.createElement("a");
    var fileRef = await getBucketgetObjecturl(
      fileProps.fileKey,
      fileProps.claimId
    );
    a.href = fileRef;
    a.target = "_blank";
    a.download = fileProps.fileName;
    a.click();

    setFileLoader("none");
    console.log("fileProps :", fileProps);
    /* await getDownloadAttachment(fileProps.claimid).then(attachmet=>{
            var blob =  b64toBlob(attachmet[0].fileBuffer, fileProps.fileExtension);
            console.log("claimAttachments : ",attachmet); 
            var FileSaver = require('file-saver');
            //var blob1 = new Blob([blob],{type:'application/pdf;base64'});//{type: fileProps.fileExtension+";base64"});//{type: "text/plain;charset=utf-8"}
            FileSaver.saveAs(blob, fileProps.fileName);
            setFileLoader("none");
        });*/
  };

  return (
    <Dialog title={fromTitle} onClose={props.cancelEdit}>
      <div className="PICCComp">
        <Form
          initialValues={props.item}
          onSubmit={props.onSubmit}
          render={(formRenderProps) => (
            <FormElement style={{ width: 960, height: 580 }}>
              <fieldset className={"k-form-fieldset"}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {props.mode == "edit" && (
                    <Field
                      id={"incidentNum"}
                      name={"incidentNum"}
                      label={"Incident #"}
                      component={FormInput}
                      readOnly={true}
                      //validator={nameValidator}
                    />
                  )}
                  <Field
                    id={"mcnNum"}
                    name={"mcnNum"}
                    label={<LabelSpec>MCN #</LabelSpec>}
                    component={FormInput}
                    hint={"Hint: Year -7 numbers sequential"}
                  />
                  {props.mode == "edit" && (
                    <>
                      <Field
                        id={"intakeDate"}
                        name={"createdDate"}
                        label={"Intake Date"}
                        //hint={'Hint: Should be greater than today'}
                        component={FormDatePicker}
                        currentDate={false}
                        disabled={true}
                        //value={props.intakeDate}
                        //validator={arrivalDateValidator}
                        //defaultValue={props.item.createdDate !==  undefined ? new Date(props.item.createdDate) : null }
                        wrapperStyle={{ width: "25%", marginRight: "18px" }}
                      />
                      <Field
                        id={"status"}
                        name={"status"}
                        label={"PICC Status"}
                        // hint={'Hint: Enter your personal email address.'}
                        //type={'email'}
                        data={statusOptions}
                        component={FormDropDownList}
                        style={{ width: "120px" }}
                        //validator={emailValidator}
                      />
                    </>
                  )}
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Field
                    id={"protocolNum"}
                    name={"protocolNum"}
                    label={<LabelSpec req={true}>Protocol Number</LabelSpec>}
                    layout={"horizontal"}
                    component={FormAutoComplete}
                    data={filteredProtocolData}
                    textField={"ProtocolNumber"}
                    placeholder="Start typing a Protocol Number (e.g. GS-US-380-4424)"
                    spellcheck="false"
                    onOpen={() => {
                      setProtocolsOpen(true);
                    }}
                    //onFoc={e => setProtocolsOpen(true)}
                    onClose={() => setProtocolsOpen(false)}
                    //onFocus={() => { console.log("focus", protocolsOpen); setProtocolsOpen(true) }}
                    //onClose={() => { console.log("close", protocolsOpen); setProtocolsOpen(false) }}
                    //opened={protocolsOpen}
                    loading={loading}
                    allowCustom={true}
                    onChange={(e) => {
                      onProtocolChange(e, formRenderProps);
                    }}
                    wrapperStyle={{ width: "30%" }}
                    required={true}
                  ></Field>

                  <Field
                    id={"indication"}
                    name={"indication"}
                    label={"Indication"}
                    layout={"horizontal"}
                    spellcheck="false"
                    component={FormComboBox}
                    data={filteredIndicationData}
                    onFocus={(e: any) => {
                      console.log(e.target.value);
                    }}
                    required={true}
                    //value={indication}
                    //val={indication}
                    //onChange={onIndicationChange}
                  ></Field>

                  <Field
                    id={"product"}
                    name={"product"}
                    label={<LabelSpec req={true}>Product</LabelSpec>}
                    layout={"horizontal"}
                    spellcheck="false"
                    component={FormComboBox}
                    data={filteredProductData}
                    textField={"Product"}
                    required={true}
                    //val={product}
                    //onChange={onProductChange}
                  ></Field>
                </div>
               
                <div
                  style={{
                    justifyContent: "space-between",
                    display: siteData.length < 1 ? "none" : "flex",
                  }}
                >
                  <Field
                    id={"site"}
                    name={"site"}
                    label={<LabelSpec req={false}>Site</LabelSpec>}
                    layout={"horizontal"}
                    component={FormComboBox}
                    data={siteData}
                    textField={"Site"}
                    placeholder=""
                    spellcheck="false"
                    disabled={siteData.length < 1}
                    hint={
                      siteData.length < 1
                        ? "Select a Protocol to show Sites."
                        : null
                    }
                    //onFocus={() => { console.log("focus", protocolsOpen); setProtocolsOpen(true) }}
                    //onClose={() => { console.log("close", protocolsOpen); setProtocolsOpen(false) }}
                    //opened={protocolsOpen}
                    onChange={(e) => {
                      onSiteChange(e, formRenderProps);
                    }}
                    wrapperStyle={{ width: "30%" }}
                  ></Field>
                  <Field
                    id={"piFullName"}
                    name={"piFullName"}
                    label={<LabelSpec req={false}>PI Name</LabelSpec>}
                    layout={"horizontal"}
                    component={FormComboBox}
                    disabled={siteData.length < 1}
                    data={piList}
                    textField={"PIFullName"}
                    //val={selectedPI}
                    //onChange={onPIChange}
                  ></Field>
                  <Field
                    id={"country"}
                    name={"siteCountry"}
                    label={<LabelSpec req={false}>Country</LabelSpec>}
                    layout={"horizontal"}
                    component={FormComboBox}
                    disabled={siteData.length < 1}
                    data={countryData}
                    //val={filteredCountry}
                    //onChange={onCountryChange}
                  ></Field>
                </div>
                <div
                  style={{ display: siteData.length > 0 ? "block" : "block" }}
                >
                  <fieldset className={"k-form-fieldset"}>
                    <Field
                      id={"comments"}
                      name={"comments"}
                      label={<LabelSpec req={false}>Comments</LabelSpec>}
                      //label={'Claim Synopsis'}
                      placeholder={
                        "Provide additional details which includes Site, PI and Country details."
                      }
                      component={FormTextArea}
                    />
                  </fieldset>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Field
                    id={"patientId"}
                    name={"patientId"}
                    label={"Subject ID"}
                    layout={"horizontal"}
                    component={FormInput}
                  />
                  <Field
                    id={"patientAge"}
                    name={"patientAge"}
                    label={"Subject Age"}
                    layout={"horizontal"}
                    component={FormNumericTextBox}
                  />
                  <Field
                    id={"patientGender"}
                    name={"patientGender"}
                    label={"Gender"}
                    layout={"horizontal"}
                    component={FormRadioGroup}
                    data={genders}
                  />
                </div>
              </fieldset>
              <fieldset className={"k-form-fieldset"}>
                <legend
                  className={"k-form-legend"}
                  style={{ marginBottom: "0" }}
                >
                  Claim Synopsis
                </legend>
                <Field
                  id={"claimDescription"}
                  name={"claimDescription"}
                  //label={'Claim Synopsis'}
                  placeholder={"Provide an overview of the claim."}
                  component={FormTextArea}
                />
              </fieldset>
              <fieldset className={"k-form-fieldset"}>
                <legend className={"k-form-legend"}>Risk Management</legend>
                <div className={"riskManagement"} style={{ display: "flex" }}>
                  <Field
                    id={"claimNum"}
                    name={"claimNum"}
                    label={"Claim #"}
                    layout={"vertical"}
                    component={FormInput}
                  />
                  <Field
                    id={"reportedToInsurer"}
                    name={"reportedToInsurer"}
                    label={"Reported to Insurer"}
                    layout={"horizontal"}
                    component={FormCheckbox}
                  />
                  <Field
                    id={"death"}
                    name={"death"}
                    label={"Death"}
                    layout={"horizontal"}
                    component={FormCheckbox}
                  />
                </div>
                <Field
                  id={"insuranceComments"}
                  name={"insuranceComments"}
                  label={"Insurance Comments"}
                  component={FormTextArea}
                />
              </fieldset>
              <fieldset className={"k-form-fieldset"}>
                <legend className={"k-form-legend"}>
                  Causality Assessment{" "}
                </legend>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Field
                    id={"piAssessment"}
                    name={"piAssessment"}
                    label={"PI Assessment"}
                    layout={"horizontal"}
                    component={FormDropDownList}
                    data={assessmentPIOptions}
                    defaultItem={"--"}
                    wrapperStyle={{ width: "30%" }}
                  />
                  <Field
                    id={"mmAssessment"}
                    name={"mmAssessment"}
                    label={"MM Assessment"}
                    layout={"horizontal"}
                    component={FormDropDownList}
                    data={assessmentPIOptions}
                    defaultItem={"--"}
                    wrapperStyle={{ width: "30%" }}
                  />
                  <Field
                    id={"glpsAssessment"}
                    name={"glpsAssessment"}
                    label={"GLPS Assessment"}
                    layout={"horizontal"}
                    component={FormDropDownList}
                    data={assessmentDDOptions}
                    defaultItem={"--"}
                    wrapperStyle={{ width: "30%" }}
                  />
                </div>
                <Field
                  id={"summaryOfAssessment"}
                  name={"summaryOfAssessment"}
                  label={"Summary Of Assessment"}
                  component={FormTextArea}
                />
              </fieldset>
              <fieldset className={"k-form-fieldset"}>
                <legend
                  className={"k-form-legend"}
                  style={{ marginBottom: "0" }}
                >
                  Claim Status
                </legend>
                <Field
                  id={"claimStatus"}
                  name={"claimStatus"}
                  //label={'Claim Synopsis'}
                  placeholder={"Provide a description of the claim's status"}
                  component={FormTextArea}

                />
                                  <Field
                                    id={"dateOfInitialClinicalClaim"}
                                    name={"dateOfInitialClinicalClaim"}
                                    label={"Date Of Initial Clinical Claim"}
                                    component={FormDatePicker}
                                    //defaultValue={props.item.medicareReportingDate  !== undefined? new Date(props.item.medicareReportingDate) : null}
                                    wrapperStyle={{ width: "25%", marginRight: "18px" }}
                                  />
              </fieldset>
              <fieldset className={"k-form-fieldset"}>
                <legend className={"k-form-legend"}>Financial Claim</legend>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Field
                    id={"siteExpenses"}
                    name={"siteExpenses"}
                    label={"Site Expenses"}
                    placeholder={"$0.00"}
                    format={"c2"}
                    component={FormNumericTextBox}
                  />
                  <Field
                    id={"patientExpenses"}
                    name={"patientExpenses"}
                    label={"Patient Expenses"}
                    placeholder={"$0.00"}
                    format={"c2"}
                    component={FormNumericTextBox}
                  />
                  <Field
                    id={"amountClaimed"}
                    name={"amountClaimed"}
                    label={"Amount Claimed"}
                    placeholder={"$0.00"}
                    format={"c2"}
                    component={FormNumericTextBox}
                  />
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Field
                    id={"amountPaidByGilead"}
                    name={"amountPaidByGilead"}
                    label={"Amount Paid by Gilead"}
                    placeholder={"$0.00"}
                    format={"c2"}
                    component={FormNumericTextBox}
                  />
                  <Field
                    id={"amountPaidByInsurer"}
                    name={"amountPaidByInsurer"}
                    label={"Amount Paid by Insurer"}
                    placeholder={"$0.00"}
                    format={"c2"}
                    component={FormNumericTextBox}
                  />
                  <Field
                    id={"amountPaidToInsurer"}
                    name={"amountPaidToInsurer"}
                    label={"Amount Paid to Insurer"}
                    placeholder={"$0.00"}
                    format={"c2"}
                    component={FormNumericTextBox}
                  />
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Field
                    id={"invoiceNum"}
                    name={"invoiceNum"}
                    label={"Invoice #"}
                    component={FormInput}
                  />
                  <Field
                    id={"invoiceDate"}
                    name={"invoiceDate"}
                    label={"Invoice Date"}
                    component={FormDatePicker}
                    currentDate={false}
                    //defaultValue={props.item.invoiceDate !== undefined ? new Date(props.item.invoiceDate) : null}
                    wrapperStyle={{ width: "25%", marginRight: "18px" }}
                  />
                </div>
              </fieldset>
              <fieldset className={"k-form-fieldset"}>
                <legend className={"k-form-legend"}>Medicare Reporting</legend>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Field
                    id={"medicareReport"}
                    name={"includesMedicareReport"}
                    label={"Medicare Report"}
                    component={FormCheckbox}
                    //validator={termsValidator}
                  />
                  <Field
                    id={"medicareReporting"}
                    name={"medicareReporting"}
                    label={"Medicare Reporting Number"}
                    component={FormInput}
                  />
                  <Field
                    id={"medicareReportingDate"}
                    name={"medicareReportingDate"}
                    label={"Medicare Reporting Date"}
                    component={FormDatePicker}
                    //defaultValue={props.item.medicareReportingDate  !== undefined? new Date(props.item.medicareReportingDate) : null}
                    wrapperStyle={{ width: "25%", marginRight: "18px" }}
                  />
                </div>
              </fieldset>
              <fieldset className={"k-form-fieldset"}>
                <legend className={"k-form-legend"}>PICC Assessment</legend>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Field
                    id={"piccAssessment"}
                    name={"piccAssessment"}
                    label={"PICC Assessment"}
                    layout={"horizontal"}
                    component={FormDropDownList}
                    data={piccAssessOptions}
                    defaultItem={"--"}
                    wrapperStyle={{ width: "30%" }}
                  />
                  <Field
                    id={"piccAssessmentDate"}
                    name={"piccAssessmentDate"}
                    label={"PICC Assessment Date"}
                    component={FormDatePicker}
                    //defaultValue={props.item.piccAssessmentDate  !== undefined? new Date(props.item.piccAssessmentDate) : null}
                    wrapperStyle={{ width: "25%", marginRight: "18px" }}
                  />
                </div>
              </fieldset>
              <fieldset className={"k-form-fieldset"}>
                <legend className={"k-form-legend"}>Attachments</legend>
                <div className="divAttachSection">
                  <input
                    type="file"
                    id="newfile"
                    multiple
                    accept=".pdf,.doc,.docx,.xlsx,.xls,.msg"
                    onChange={props.fileAttach.bind(this, attachmentFiles)}
                  />
                  {attachmentFiles.length > 0 ? (
                    <div style={{ padding: "6px" }}>
                      <div
                        style={{
                          display: props.fileLoader,
                          textAlign: "center",
                        }}
                      >
                        {" "}
                        <img src={Loader} style={{ width: "100px" }} />
                      </div>
                      {attachmentFiles.map((attFile, x) => (
                        <RenderAttachments
                          data={attFile}
                          removefile={onDeleteFile}
                          download={onDownloadFile}
                          displayLoader={FileLoader}
                        />
                      ))}
                    </div>
                  ) : undefined}
                </div>
              </fieldset>

              <fieldset className={"k-form-fieldset"}>
                <span className={"k-form-separator"} />
                {/* <Field
                  id={'terms'}
                  name={'terms'}
                  label={'I agree with terms and conditions'}
                  component={FormCheckbox}
              //validator={termsValidator}
              /> */}
                <div className="k-form-buttons form-bottom-padding">
                  <Button
                    type={"submit"}
                    disabled={!formRenderProps.allowSubmit}
                  >
                    Submit Injury Claim
                  </Button>
                  <Button
                    className="k-button k-primary"
                    onClick={formRenderProps.onFormReset}
                  >
                    Clear
                  </Button>
                  <Button
                    className="k-button k-primary"
                    onClick={props.cancelEdit}
                  >
                    Cancel
                  </Button>
                </div>
              </fieldset>
            </FormElement>
          )}
        ></Form>
      </div>
    </Dialog>
  );
};

export default PICCForm;
/**
 * Change On 06-18-2021
 * 1. Rename ‘Global Patient Safety’ to  ‘Causality  Assessment’.
 */
