import * as React from "react";
import {
  GridColumnMenuFilter,
  GridColumnMenuCheckboxFilter,
  GridColumnMenuProps,
  // ColumnMenuCheckboxFilterProps
} from "@progress/kendo-react-grid";

export const ColumnMenu = (props: GridColumnMenuProps) => {
  return (
    <div>
      <GridColumnMenuFilter {...props} expanded={true} />
    </div>
  );
};

export const ColumnMenuCheckboxFilter = (props: any) => {
  const status = [];
  status.push({ status: "New" });
  status.push({ status: "In Progress" });
  status.push({ status: "Complete" });
  status.push({ status: "Closed" });

  return (
    <div>
      <GridColumnMenuCheckboxFilter {...props} data={status} expanded={true} />
    </div>
  );
};
