import React, { Component, createContext, useState, useContext } from "react";
const clientIdForSignUp = `${process.env.REACT_APP_DOMAIN_URL}/oauth2/authorize?client_id=${process.env.REACT_APP_USER_CLIENT_ID}&IDPProvider=GileadSSOSignIn&response_type=token&scope=email+openid+profile&redirect_uri=${process.env.REACT_APP_CLOUD_FRONT_URL}`;

class Appauth extends Component {
  public componentDidMount(): void {
    const params = new URLSearchParams(window.location.hash.substr(1));
    console.log("params", params.get("access_token"));
    localStorage.setItem("idtoken", params.get("id_token") || "");
    localStorage.setItem("accesstoken", params.get("access_token") || "");
    localStorage.setItem("expires_in", params.get("expires_in") || "");
    localStorage.setItem(
      "expires_at",
      (
        new Date().getTime() +
        Number(params.get("expires_in")) * 1000
      ).toString() || ""
    );
  }
  localStorageUpdated() {
    if (
      localStorage.getItem("accesstoken") === "" ||
      localStorage.getItem("accesstoken") === null
    )
      window.location.href = clientIdForSignUp;
    return null;
  }

  render() {
    return <div>{this.localStorageUpdated()}</div>;
  }
}

export default Appauth;
