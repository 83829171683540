import React from "react";
import logo from "./logo.svg";
import "./App.css";
import PiccApp from "./components/DosnpPicc";
import { Authendication } from "./auth/Authorizer";
import Appauth from "./auth/appAuth";
function App() {
  return (
    <div className="App">
      <Appauth />
      <PiccApp></PiccApp>
    </div>
  );
}

export default App;
