import axios from "axios";

let bioStudiesUrl = process.env.REACT_APP_GileadCommon_URL + "/api/getBioStudies";
let bioStudiesFilteredUrl =
  process.env.REACT_APP_GileadCommon_URL + "/api/findStudiesByProtocol";
let allSitesURL = process.env.REACT_APP_GileadCommon_URL + "/api/getStudySites";
let findSitesURL = process.env.REACT_APP_GileadCommon_URL + "/api/getSitesByStudy";
const clientIdForSignUp = `${process.env.REACT_APP_DOMAIN_URL}/oauth2/authorize?client_id=${process.env.REACT_APP_USER_CLIENT_ID}&response_type=token&IDPProvider=GileadSSOSignIn&scope=email+openid+profile&redirect_uri=${process.env.REACT_APP_CLOUD_FRONT_URL}`;
let getcurrentUser =
  process.env.REACT_APP_serverUrlPrd + ":8077/api/createSession";
let getMe = process.env.REACT_APP_serverUrlPrd + ":8077/api/getCurrentUser";

/**AWS Lambda */
//let aws_labda_baseurl="https://m0gm9qxvyb.execute-api.us-east-1.amazonaws.com/dev";
//Gilead
//let aws_labda_baseurl="https://19982omap7.execute-api.us-west-2.amazonaws.com/dev";

//API EndPoints
//Claims
let getAllClaimsUrl =
  process.env.REACT_APP_aws_lambda_baseurl + "/api/getClaims";
let getLatestClaimIDUrl =
  process.env.REACT_APP_aws_lambda_baseurl + "/api/getClaimTopId";
let getClaimAttachmentsByIDUrl =
  process.env.REACT_APP_aws_lambda_baseurl + "/api/getClaimAttachments/";
let getClaimAttachmentsDetails =
  process.env.REACT_APP_aws_lambda_baseurl + "/api/getAttachmentMetadata/";
let getAttachment =
  process.env.REACT_APP_aws_lambda_baseurl + "/api/getClaimAttachments/";
let getUsername = process.env.REACT_APP_aws_lambda_baseurl + "/getUsername";
export async function getCognitoUser() {
  return new Promise((resolve, reject) => {
    fetch(getUsername, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("accesstoken"),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        const userResponse = JSON.parse(response.body);
        resolve({
          user: userResponse.username.split("_")[1],
        });
      })
      .catch((error) => {
        if (Number(localStorage.getItem("expires_at")) < new Date().getTime()) {
          window.location.href = clientIdForSignUp;
        }
        return reject(error);
      });
  });
}

export default async function getProtocols() {
  return new Promise((resolve, reject) => {
    fetch(bioStudiesUrl)
      .then((response) => response.json())
      .then((response) => {
        if(response.statusCode == 200){
          return resolve(response.body);
        }else{
          return resolve([]);
        }
        
      })
      .catch((error) => {
        if (Number(localStorage.getItem("expires_at")) < new Date().getTime()) {
          window.location.href = clientIdForSignUp;
        }
        return reject(error);
      });
  });
}

export async function getProtocolsByNumber(protocolNumberFrag) {
  return new Promise((resolve, reject) => {
    //return resolve(_staticProtocols);
    fetch(`${bioStudiesFilteredUrl}/${protocolNumberFrag}`)
      .then((response) => response.json())
      .then((response) => {
        if(response.statusCode == 200){
          return resolve(response.body);
        }else{
          return resolve([]);
        }
      })
      .catch((error) => {
        if (Number(localStorage.getItem("expires_at")) < new Date().getTime()) {
          window.location.href = clientIdForSignUp;
        }
        return reject(error);
      });
  });
}

export async function getSite() {
  return new Promise((resolve, reject) => {
    fetch(allSitesURL)
      .then((response) => response.json())
      .then((response) => {
        if(response.statusCode == 200){
          return resolve(response.body);
        }else{
          return resolve([]);
        }
      })
      .catch((error) => {
        if (Number(localStorage.getItem("expires_at")) < new Date().getTime()) {
          window.location.href = clientIdForSignUp;
        }
        return reject(error);
      });
  });
}

export async function getSitesByStudy(protocolNumber) {
  return new Promise((resolve, reject) => {
    //return resolve(_staticProtocols);
    fetch(`${findSitesURL}/${protocolNumber}`)
      .then((response) => response.json())
      .then((response) => {
        if(response.statusCode == 200){
          return resolve(response.body);
        }else{
          return resolve([]);
        }
      })
      .catch((error) => {
        if (Number(localStorage.getItem("expires_at")) < new Date().getTime()) {
          window.location.href = clientIdForSignUp;
        }
        return reject(error);
      });
  });
}

export async function getAllClaims() {
  return new Promise(async (resolve, reject) => {
    axios
      .get(getAllClaimsUrl, {
        headers: {
          Authorization: localStorage.getItem("accesstoken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return resolve(JSON.parse(response.data.body));
      })
      .catch((error) => {
        if (Number(localStorage.getItem("expires_at")) < new Date().getTime()) {
          window.location.href = clientIdForSignUp;
        }
        return reject(error);
      });
  });
}

export async function getClaimAttachmentsByID(claimID) {
  return new Promise(async (resolve, reject) => {
    var url = getClaimAttachmentsByIDUrl + claimID;
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("accesstoken"),
        },
      })
      .then((response) => {
        return resolve(JSON.parse(response.data.body));
      })
      .catch((error) => {
        if (Number(localStorage.getItem("expires_at")) < new Date().getTime()) {
          window.location.href = clientIdForSignUp;
        }
        return reject(error);
      });
  });
}

export async function getClaimAttachmentDetails(claimID) {
  return new Promise(async (resolve, reject) => {
    var url = getClaimAttachmentsDetails + claimID;
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("accesstoken"),
        },
      })
      .then((response) => {
        return resolve(JSON.parse(response.data.body));
      })
      .catch((error) => {
        if (Number(localStorage.getItem("expires_at")) < new Date().getTime()) {
          window.location.href = clientIdForSignUp;
        }
        return reject(error);
      });
  });
}

export async function getAtuAPI() {
  return new Promise(async (resolve, reject) => {
    axios
      .get(process.env.REACT_APP_access_token_url, {
        headers: {
          "Content-Type": "application/json",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("accesstoken"),
        },
      })
      .then((response) => {
        return resolve(JSON.parse(response.data.body));
      })
      .catch((error) => {
        if (Number(localStorage.getItem("expires_at")) < new Date().getTime()) {
          window.location.href = clientIdForSignUp;
        }
        return reject(error);
      });
  });
}

export async function getDownloadAttachment(id) {
  return new Promise(async (resolve, reject) => {
    var url = getAttachment + id;
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("accesstoken"),
        },
      })
      .then((response) => {
        return resolve(JSON.parse(response.data.body));
      })
      .catch((error) => {
        if (Number(localStorage.getItem("expires_at")) < new Date().getTime()) {
          window.location.href = clientIdForSignUp;
        }
        return reject(error);
      });
  });
}

export async function getNTName() {
  return new Promise(async (resolve, reject) => {
    axios
      .get(process.env.REACT_APP_serverUrlPrd + ":5555/sys/whoami")
      .then((response) => {
        return resolve(JSON.parse(response.data.body)); //.recordsets[0]);
      })
      // .catch(error => { console.log(error); console.log("ERROR URL:", error.config.url); return reject(error) });
      .catch((error) => {
        if (Number(localStorage.getItem("expires_at")) < new Date().getTime()) {
          window.location.href = clientIdForSignUp;
        }
        return reject(error);
      });
  });
}

export async function getCurrentUser() {
  return new Promise(async (resolve, reject) => {
    axios.get(getcurrentUser).then((response) => {
      return resolve(JSON.parse(response.data.body));
    });
  }).then((response) => {
    return new Promise(async (resolve, reject) => {
      axios.get(getMe).then((response) => {
        return resolve(JSON.parse(response.data.body));
      });
    });
  });
}
