import axios from "axios";
/**AWS Lambda */
//let aws_labda_baseurl="https://m0gm9qxvyb.execute-api.us-east-1.amazonaws.com/dev";
//let aws_labda_baseurl="https://19982omap7.execute-api.us-west-2.amazonaws.com/dev";

//API EndPoints
//Claims
const clientIdForSignUp = `${process.env.REACT_APP_DOMAIN_URL}/oauth2/authorize?client_id=${process.env.REACT_APP_USER_CLIENT_ID}&response_type=token&IDPProvider=GileadSSOSignIn&scope=email+openid+profile&redirect_uri=${process.env.REACT_APP_CLOUD_FRONT_URL}`;
let createPICCClaimUrl =
  process.env.REACT_APP_aws_lambda_baseurl + "/api/createClaim";
let updatePICCClaimUrl =
  process.env.REACT_APP_aws_lambda_baseurl + "/api/updateClaim";
let getLatestId =
  process.env.REACT_APP_aws_lambda_baseurl + "/api/getClaimTopId";
let createClaimAttachmentMetadta =
  process.env.REACT_APP_aws_lambda_baseurl + "/api/createAttachmentMetadata";

//S3 Action
let getUploadS3SignedURL =
  process.env.REACT_APP_aws_lambda_baseurl + "/api/getBucketputObjectSignUrl";
let getBucketgetObjectSignUrl =
  process.env.REACT_APP_aws_lambda_baseurl + "/api/getBucketgetObjectSignUrl/";
let removeBucketObject =
  process.env.REACT_APP_aws_lambda_baseurl + "/api/removeBucketObject";
let removeAttachmentMetadata =
  process.env.REACT_APP_aws_lambda_baseurl + "/api/removeAttachmentMetadata";

const folderName = "ClaimAttachments";

function formatSizeUnits(bytes) {
  if (bytes >= 1073741824) {
    bytes = (bytes / 1073741824).toFixed(2) + " GB";
  } else if (bytes >= 1048576) {
    bytes = (bytes / 1048576).toFixed(2) + " MB";
  } else if (bytes >= 1024) {
    bytes = (bytes / 1024).toFixed(2) + " KB";
  } else if (bytes > 1) {
    bytes = bytes + " bytes";
  } else if (bytes == 1) {
    bytes = bytes + " byte";
  } else {
    bytes = "0 bytes";
  }
  return bytes;
}

export async function getBucketgetObjecturl(key, claimID) {
  return new Promise((resolve, reject) => {
    let postObject = { filekey: key, claimid: claimID };
    var options = { headers: { "Content-Type": "multipart/form-data" } };
    axios
      .post(getBucketgetObjectSignUrl, postObject, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("accesstoken"),
        },
      })
      .then((response) => {
        console.log(response);
        return resolve(JSON.parse(response.data.body).preSignedUrl);
      })
      .catch((err) => {
        if (Number(localStorage.getItem("expires_at")) < new Date().getTime()) {
          window.location.href = clientIdForSignUp;
        }
        return reject(err);
      });
  });
}
export async function uploadAttachmentsintoS3(file, claimID) {
  //Adding Cliam Id to make this as unique for the Claims ID
  const fileName = claimID + "-" + file.name;
  //get PreSign URL
  var url = getUploadS3SignedURL;

  return new Promise(async (resolve, reject) => {
    await axios
      .post(
        url,
        { fileName: fileName, folder: folderName },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("accesstoken"),
          },
        }
      )
      .then((response) => {
        console.log(
          "preSignedUrl - ",
          JSON.parse(response.data.body).preSignedUrl
        );
        var options = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        axios
          .put(JSON.parse(response.data.body).preSignedUrl, file, options)
          .then((res) => {
            let postObject = {
              filekey: folderName + "/" + fileName,
              filename: file.name,
              filesize: file.size,
              fileextension: file.type,
              claimid: claimID,
            };
            axios
              .post(createClaimAttachmentMetadta, postObject, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: localStorage.getItem("accesstoken"),
                },
              })
              .then((res) => {
                console.log(response);
                return resolve(response);
              })
              .catch((err) => {
                if (
                  Number(localStorage.getItem("expires_at")) <
                  new Date().getTime()
                ) {
                  window.location.href = clientIdForSignUp;
                }
                return reject(err);
              });
            console.log(response);
            return resolve(response);
          })
          .catch((err) => {
            if (
              Number(localStorage.getItem("expires_at")) < new Date().getTime()
            ) {
              window.location.href = clientIdForSignUp;
            }
            return reject(err);
          });
      });
  });
}

export async function createAttachmentMetadata(file, claimID) {
  //Adding Cliam Id to make this as unique for the Claims ID
  const fileName = claimID + "-" + file.name;
  //get PreSign URL
  var url = getUploadS3SignedURL;

  return new Promise(async (resolve, reject) => {
    await axios
      .post(
        url,
        { fileName: fileName, folder: "ClaimAttachments" },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("accesstoken"),
          },
        }
      )
      .then((response) => {
        console.log(
          "preSignedUrl - ",
          JSON.parse(response.data.body).preSignedUrl
        );
        var options = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        axios
          .put(JSON.parse(response.data.body).preSignedUrl, file, options)
          .then((res) => {
            console.log(response);
            return resolve(response);
          })
          .catch((err) => {
            if (
              Number(localStorage.getItem("expires_at")) < new Date().getTime()
            ) {
              window.location.href = clientIdForSignUp;
            }
            return reject(err);
          });
      });
  });
}
/*
export async function uploadAttachment(file,claimID){
  console.log("uploadAttachment: ", formatSizeUnits(file.size) );
  const config = {
      headers: {
          'content-type': 'multipart/form-data'
      }
  }
  return new Promise(async (resolve,reject)=>{
    const fd= new FormData();
    fd.append("files",file);
    fd.append("claimid",claimID);
    fetch(addAttchment, { // Your POST endpoint
      method: 'POST',
      mode: "no-cors",
      body: fd // This is your file object
    }).then(response => {
        //response.json() // if the response is a JSON object
        console.log(response)
        return resolve(response.data);
      }
    ).then(success => {
      console.log(success);
     // return resolve(response.data) ;
    } // Handle the success response object
    ).catch(
      error => { console.log(error); return reject(error) } // Handle the error response object
    );
  })
}*/

export async function removeAttachment(fileProps) {
  //remove bucket object

  // remove metadata
  let postObject = {
    bucketName: fileProps.bucketName,
    fileKey: fileProps.fileKey,
    claimid: fileProps.claimId,
    fileid: fileProps.id,
  };
  return new Promise(async (resolve, reject) => {
    axios
      .post(removeBucketObject, postObject, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("accesstoken"),
        },
      })
      .then((response) => {
        console.log(response.data);
        return resolve(response.data);
      })
      .catch((error) => {
        if (Number(localStorage.getItem("expires_at")) < new Date().getTime()) {
          window.location.href = clientIdForSignUp;
        }
        return reject(error);
      });
  }).then((response) => {
    return new Promise(async (resolve, reject) => {
      axios
        .post(removeAttachmentMetadata, postObject, {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("accesstoken"),
          },
        })
        .then((response) => {
          console.log(response.data);
          return resolve(response.data);
        })
        .catch((error) => {
          if (
            Number(localStorage.getItem("expires_at")) < new Date().getTime()
          ) {
            window.location.href = clientIdForSignUp;
          }
          return reject(error);
        });
    });
  });
  /*
 // let postObject = {"fileid":fileProps.fileId, "claimid": fileProps.claimId};
  let postObject = {
    "bucketName":fileProps.bucketName,
    "fileKey":fileProps.fileKey,
    "claimid": fileProps.claimId,
    "fileid": fileProps.id
  };
  return new Promise(async (resolve, reject) => {
    axios.post(removeAttchment,postObject)
    .then(response => {
        console.log(response.data);
        return resolve(response.data) ;
    })
  .catch(error => { 
    console.log(error); 
    console.log(error);


    return reject(error) 
  });
  });*/
}

export async function updateClaim(postObject, username) {
  const currentDate = new Date();
  postObject = {
    ...postObject,
    modified: currentDate.toISOString().split('T')[0] + ' '+ currentDate.toTimeString().split(' ')[0], //new Date().toLocaleDateString("en-CA"),
    modifiedby: username,
  };
  console.log(updatePICCClaimUrl + " -- " + postObject);
  return new Promise(async (resolve, reject) => {
    axios
      .post(updatePICCClaimUrl, postObject, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("accesstoken"),
        },
      })
      .then((response) => {
        console.log(response.data);
        return resolve(response.data);
      })
      .catch((error) => {
        if (Number(localStorage.getItem("expires_at")) < new Date().getTime()) {
          window.location.href = clientIdForSignUp;
        }
        return reject(error);
      });
  });
}

export async function createClaim(postObject, username) {
  return new Promise(async (resolve, reject) => {
    axios
      .get(getLatestId, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("accesstoken"),
        },
      })
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      });
  }).then((response) => {
    return new Promise(async (resolve, reject) => {
      let currentDate = new Date();
      var nextID = parseInt("1");
      console.log("response.data.length", response.data.length);
      if (response.data.length !== 0 && response.data.length !== undefined) {
        nextID = response.data[0].Id + 1;
      }
      let dynamicID = "PICC" + currentDate.getFullYear() + "-" + nextID;
      postObject.status = "New";
      postObject = {
        ...postObject,
        incidentNum: dynamicID,
        createdDate: currentDate,
        created: currentDate.toISOString().split('T')[0] + ' '+ currentDate.toTimeString().split(' ')[0]  , //ew Date().toLocaleDateString("en-CA"),
        modified: currentDate.toISOString().split('T')[0] + ' '+ currentDate.toTimeString().split(' ')[0], //new Date().toLocaleDateString("en-CA"),
        createdby: username,
        modifiedby: username,
      };
      console.log("postObject after id : ", postObject);
      axios
        .post(createPICCClaimUrl, postObject, {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("accesstoken"),
          },
        })
        .then((response) => {
          console.log(response.data);

          return resolve(JSON.parse(response.data.body));
        })
        .catch((error) => {
          if (
            Number(localStorage.getItem("expires_at")) < new Date().getTime()
          ) {
            window.location.href = clientIdForSignUp;
          }
          return reject(error);
        });
    });
  });
}
